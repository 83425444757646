<template>
  <div class="dynamic-grid" :style="{ 'gap': gapValue, '--columns': columns }">
    <slot v-for="item, index in items" name="item" :index="index" v-bind="item" />
  </div>
</template>

<script setup lang="ts" generic="T">
const props = withDefaults(defineProps<{ items: T[]; gap?: number | string; columns?: number }>(), {
  gap: 0,
})
const gapValue = computed(() => isNumber(props.gap) ? `${props.gap}px` : props.gap)
</script>

<style lang="scss" scoped>
.dynamic-grid {
  --columns: 2;

  @media screen and (max-width: 750px) {
    --columns: 1;
  }

  display: grid;
  grid-template-columns: repeat(var(--columns), auto);
  grid-auto-rows: min-content;
}
</style>
